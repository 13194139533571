import React from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

const DesignCard = () => {
  const { ref, inView } = useInView({
    threshold: 0.1, // Trigger when 10% of the element is in view
  });

  const navigate = useNavigate(); // Initialize useNavigate hook
  const handleGalleryRedirect = () => {
    navigate('/gallery'); // Redirect to the /contactus path
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f5e2c7',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: { xs: '10px', md: '0' }
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: '1200px',
          height: '100%',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center'
        }}
      >
        {/* Left Image Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: { xs: '20px', md: '0' }
          }}
        >
          <Box
            component="img"
            src="https://i.postimg.cc/gJHVsWjZ/e8d1df142276763-6263bba4c7eb4.png" // Replace with your actual image URL
            alt="Design Image"
            sx={{
              width: '100%',
              height: { xs: '50vh', md: '100vh' },
              objectFit: 'cover',
            }}
          />
        </Grid>

        {/* Right Content Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            padding: { xs: '20px', md: '40px' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <motion.div
            ref={ref}
            initial={{ opacity: 0, x: -100 }} // Start off-screen to the left
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }} // Animate into view
            transition={{ duration: 0.5 }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
                mb: 1,
                fontSize: { xs: '1.5rem', md: '2rem' }
              }}
            >
              Personalized Design Solutions
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{
                mb: 2,
                fontSize: { xs: '1rem', md: '1.25rem' },
                color: "#8E7C57"
              }}
            >
              <b>Transform your space with elegance and style.</b>
            </Typography>

            {/* Star Ratings */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2
              }}
            >
              {[...Array(5)].map((_, index) => (
                <StarIcon
                  key={index}
                  sx={{
                    color: '#FFD700',
                    marginRight: '5px',
                    fontSize: { xs: '1.2rem', md: '1.5rem' }
                  }}
                />
              ))}
            </Box>

            <Typography
              variant="body1"
              sx={{
                mb: 2,
                fontSize: { xs: '0.875rem', md: '1rem' },
                color: "#8E7C57"
              }}
            >
              <b>
                At NRH Interiors, we specialize in creating personalized design solutions that reflect your unique style.
                Whether you're looking for a cozy living space or a modern office environment, our team is dedicated
                to transforming your vision into reality.
              </b>
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 3,
                flexDirection: { xs: 'column', md: 'row' },
                gap: { xs: '20px', md: '0' }
              }}
            >
              <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                <Typography variant="body2" sx={{ fontWeight: 'bold', }}>
                  Budget
                </Typography>
                <Typography variant="body1" sx={{ color: "#8E7C57" }}><b>$5,000</b></Typography>
              </Box>
              <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  Styles
                </Typography>
                <Typography variant="body1" sx={{ color: "#8E7C57" }}><b>Glam, Bohemian, Modern</b></Typography>
              </Box>
            </Box>

            <Button
              onClick={handleGalleryRedirect}
              variant="outlined" // Change to outlined variant
              endIcon={<span>&rarr;</span>}
              sx={{
                borderColor: '#8E7C57', // Set border color
                color: '#8E7C57', // Set text color
                textTransform: 'none',
                fontWeight: 'bold',
                alignSelf: { xs: 'center', md: 'flex-start' },
                '&:hover': {
                  backgroundColor: '#8E7C57', // Background color on hover
                  color: '#fff', // Text color on hover
                },
              }}
            >
              View Gallery
            </Button>

          </motion.div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DesignCard;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function AboutSection() {
  const { ref, inView } = useInView({
    threshold: 0.1, // Trigger when 10% of the element is in view
  });

  return (
    <Box 
      sx={{
        backgroundColor: '#f6f0e6', // Beige background color
      }}
    >
      {/* Background Image */}
      <Box
        sx={{
          backgroundImage: `url('https://i.postimg.cc/PqCqWFJv/modern-minimalist-office.jpg')`, // Add your image path here
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '185px', // Fixed height for the image
        }}
      >
        {/* Heading over the image */}
        <Typography
          variant="h3"
          sx={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // Centering the heading
            color: '#fff', // White text color
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: '"Roboto", sans-serif',
            textShadow: `
              1px 1px 0 #000,
              -1px -1px 0 #000,
              1px -1px 0 #000,
              -1px 1px 0 #000,
              0 1px 0 #000,
              0 -1px 0 #000`,
          }}
        >
          About NRH Interiors
        </Typography>
      </Box>

      {/* Content Section */}
      <Box
        sx={{
          padding: '80px 60px',
          maxWidth: 'auto',
          margin: '0 auto',
          backgroundColor:"#e6d0a6"
        }}
      >
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 20 }} // Start slightly below and transparent
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }} // Animate into view
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h4" gutterBottom 
          sx={{
            fontWeight: 'bold',
            mb: 2,
            fontFamily: '"Space Grotesk", sans-serif',
            fontWeight: 700 // Adjust font weight as needed
          }}
          >
            Welcome to NRH Interiors
          </Typography>
          <Typography variant="body1"
          sx={{
            fontWeight: 'bold',
            mb: 2,
            fontSize:"17px",
             color:"#8E7C57",
            fontFamily: '"Space Grotesk", sans-serif',
            fontWeight: 700 // Adjust font weight as needed
          }} >
            At NRH Interiors, we are passionate about transforming spaces into stunning reflections of your style and personality. With years of experience in the design and construction industry, our dedicated team works closely with you to understand your vision and bring it to life. 
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 ,
            fontWeight: 'bold',
            mb: 2,
            fontSize:"17px",
            fontFamily: '"Space Grotesk", sans-serif',
             color:"#8E7C57",
            fontWeight: 700 // Adjust font weight as needed
          }}>
            Our commitment to quality and excellence ensures that every project, whether big or small, is executed with precision and care. From conceptual design to final touches, we prioritize your needs and strive to exceed your expectations.
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 ,fontWeight: 'bold',
            mb: 2,
            fontSize:"17px",
            fontFamily: '"Space Grotesk", sans-serif',
             color:"#8E7C57",
            fontWeight: 700 }}>
            Join us in creating beautiful interiors that inspire and elevate your everyday living. Let’s build your dream space together!
          </Typography>
        </motion.div>
      </Box>
    </Box>
  );
}

export default AboutSection;

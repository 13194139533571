import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';
import { useInView } from 'react-intersection-observer';

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

function InfrastructureSection() {
  const { ref: headingRef, inView: headingInView } = useInView({
    triggerOnce: true, // Animation only triggers once
    threshold: 0.2, // 20% of the element needs to be visible for the animation to trigger
  });

  const { ref: boxRef, inView: boxInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <Box
      sx={{
        backgroundColor: '#f4efe9', // Light beige background color
        padding: { xs: '20px', md: '40px 20px' }, // Responsive padding
      }}
    >
      {/* Section Heading */}
      <Typography
        ref={headingRef}
        variant="h3"
        sx={{
          fontSize: { xs: '28px', md: '36px' }, // Responsive font size
          fontWeight: 'bold',
          color: '#9e8856', // Brownish color for the heading
          marginBottom: '20px',
          textAlign: 'left',
          opacity: headingInView ? 1 : 0,
          transform: headingInView ? 'translateY(0)' : 'translateY(20px)',
          transition: 'opacity 0.6s ease-in-out, transform 0.6s ease-in-out', // Transition on scroll
        }}
      >
        Our Infrastructure
      </Typography>

      {/* Shared Container for Image and Text */}
      <Box
        ref={boxRef}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, // Stack on mobile, row on larger screens
          backgroundColor: '#e6d0a6', // Beige background for the box
          borderRadius: '8px',
          overflow: 'hidden', // Ensure rounded corners affect both content
          padding: { xs: '15px', md: '20px' }, // Responsive padding
          maxHeight: { xs: 'auto', md: '400px' }, // Responsive max height
          opacity: boxInView ? 1 : 0,
          transform: boxInView ? 'translateY(0)' : 'translateY(20px)',
          transition: 'opacity 0.6s ease-in-out, transform 0.6s ease-in-out', // Transition on scroll
        }}
      >
        {/* Image Section */}
        <Box
          component="img"
          src="https://i.postimg.cc/kgQBh9GF/Screenshot-2024-09-17-161958.png" // Replace with your image URL
          alt="Our Factory"
          sx={{
            width: { xs: '100%', md: '50%' }, // Full width on mobile, 50% on larger screens
            height: 'auto', // Auto height to maintain aspect ratio
            borderRadius: '8px',
            objectFit: 'cover',
            marginBottom: { xs: '15px', md: '0' }, // Margin between image and text on mobile
            marginRight: { xs: '0', md: '20px' }, // Space between image and text on larger screens
          }}
        />

        {/* Text Section */}
        <Box
          sx={{
            width: { xs: '100%', md: '50%' }, // Full width on mobile, 50% on larger screens
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', // Center the text vertically
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: '#9e8856', // Brownish color for the subheading
              marginBottom: '10px',
              fontFamily: '"Space Grotesk", sans-serif',
              fontSize: { xs: '24px', md: '28px' }, // Responsive font size
              fontWeight: 700,
            }}
          >
            Our Manufacturing Facility
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#6d5e3f', // Darker brown for the text
              lineHeight: '1.6',
              fontSize: { xs: '15px', md: '17px' }, // Responsive font size
              fontFamily: '"Space Grotesk", sans-serif',
              fontWeight: 700,
            }}
          >
            At NRH Interiors, our state-of-the-art manufacturing facility is designed to ensure the highest quality standards. Equipped with the latest technology, we specialize in creating custom interiors that meet the diverse needs of our clients. Our skilled artisans combine traditional craftsmanship with modern techniques to deliver exceptional results that exceed expectations.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default InfrastructureSection;

import React, { useState } from 'react';
import { Link, Box, Grid, TextField, Button, Typography, IconButton, useMediaQuery } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ChatIcon from '@mui/icons-material/Chat';
import CancelIcon from '@mui/icons-material/Cancel';
import { LocationOn, Phone, Email } from "@mui/icons-material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';


function ContactUs() {
  const [showSocialIcons, setShowSocialIcons] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const handleToggleIcons = () => {
    setShowSocialIcons(!showSocialIcons);
  };
  return (
    <div>
      <Header />
      
      {/* Floating Social Icons */}
       {/* Social Icons for Mobile */}
       {isMobile ? (
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            right: '10px',
            transform: 'translateY(-50%)',
            zIndex: 1000,
          }}
        >
          <IconButton
            onClick={handleToggleIcons}
            sx={{
              backgroundColor: '#B59F6A',
              color: 'white',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              '&:hover': {
                backgroundColor: '#967C55',
              },
            }}
          >
            {showSocialIcons ? <CancelIcon /> : <ChatIcon />}
          </IconButton>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              marginTop: '10px',
              opacity: showSocialIcons ? 1 : 0,
              transform: showSocialIcons ? 'translateX(0)' : 'translateX(100%)',
              transition: 'transform 0.3s ease, opacity 0.3s ease',
            }}
          >
            {showSocialIcons && (
              ['https://i.postimg.cc/8zMNgfw8/instagram.png', 'https://i.postimg.cc/65TCctc4/facebook.png', 'https://i.postimg.cc/RCYWMf1q/telephone.png', 'https://i.postimg.cc/pdv9wHfZ/whatsapp.png'].map((src, index) => (
                <IconButton
                  key={index}
                  href={index === 0 ? 'https://instagram.com' : index === 1 ? 'https://facebook.com' : index === 2 ? 'tel:+123456789' : 'https://wa.me/123456789'}
                  target={index < 2 ? "_blank" : undefined}
                  sx={{
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'scale(1.1)',
                      animation: 'lightning 0.3s forwards',
                    },
                    '@keyframes lightning': {
                      '0%': {
                        boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
                        transform: 'scale(1)',
                      },
                      '50%': {
                        boxShadow: '0 0 20px rgba(255, 255, 255, 1)',
                        transform: 'scale(1.1)',
                      },
                      '100%': {
                        boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
                        transform: 'scale(1)',
                      },
                    },
                  }}
                >
                  <img src={src} alt="Icon" style={{ width: '40px', height: '40px', filter: 'drop-shadow(0 0 5px white)' }} />
                </IconButton>
              ))
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            right: '10px',
            transform: 'translateY(-50%)',
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          {['https://i.postimg.cc/8zMNgfw8/instagram.png', 'https://i.postimg.cc/65TCctc4/facebook.png', 'https://i.postimg.cc/RCYWMf1q/telephone.png', 'https://i.postimg.cc/pdv9wHfZ/whatsapp.png'].map((src, index) => (
            <IconButton
              key={index}
              href={index === 0 ? 'https://instagram.com' : index === 1 ? 'https://facebook.com' : index === 2 ? 'tel:+123456789' : 'https://wa.me/123456789'}
              target={index < 2 ? "_blank" : undefined}
              sx={{
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.1)',
                  animation: 'lightning 0.3s forwards',
                },
                '@keyframes lightning': {
                  '0%': {
                    boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
                    transform: 'scale(1)',
                  },
                  '50%': {
                    boxShadow: '0 0 20px rgba(255, 255, 255, 1)',
                    transform: 'scale(1.1)',
                  },
                  '100%': {
                    boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
                    transform: 'scale(1)',
                  },
                },
              }}
            >
              <img src={src} alt="Icon" style={{ width: '40px', height: '40px', filter: 'drop-shadow(0 0 5px white)' }} />
            </IconButton>
          ))}
        </Box>
      )}


      {/* Header Image with Title */}
      <div>
        <Box
          sx={{
            backgroundImage: `url('https://i.postimg.cc/PqCqWFJv/modern-minimalist-office.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '185px',
          }}
        >
          <Typography
          variant="h3"
          sx={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // Centering the heading
            color: '#fff', // White text color
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: '"Roboto", sans-serif',
            textShadow: `
              1px 1px 0 #000,
              -1px -1px 0 #000,
              1px -1px 0 #000,
              -1px 1px 0 #000,
              0 1px 0 #000,
              0 -1px 0 #000`,
          }}
        >
          Contact US
        </Typography>
        </Box>
      </div>

      {/* Main Content: Map on the Left, Form on the Right */}
      <Box
      sx={{
        backgroundColor: '#e8d8b8',
        padding: '40px 20px',
      }}
    >
      <Box sx={{ textAlign: "center", marginBottom: "40px" }}>
  <Typography variant="h4" gutterBottom sx={{ color: "#a89160" }}>
    Request a Quote
  </Typography>
  <Typography variant="body1" paragraph>
    Contact us for any information or assistance. We're happy to assist with all your needs!
  </Typography>
</Box>

      <Grid container spacing={4} >
        {/* Left Section */}
        <Grid item xs={12} md={6}>
          {/* Address Section */}
          <Box
            sx={{
              backgroundColor: "#fff",
              padding: { xs: "15px", sm: "20px" },
              marginBottom: "20px",
              borderRadius: "8px",
              border: `1px solid #a89160`,
              transition: "transform 0.3s ease-in-out",
              textAlign: { xs: "center", md: "left" },
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Typography variant="h6" sx={{ display: "flex", alignItems: "center", justifyContent: { xs: "center", md: "flex-start" }, marginBottom: "10px", color: "#a89160" }}>
              <LocationOn sx={{ marginRight: "10px" }} />
              Address
            </Typography>
            <Typography>Hilite Business Park , Calicut , Kerala</Typography>
            <Link
              href="https://www.google.com/maps/search/?api=1&query=402671+Sugar+Camp+Road,+Owatonna,+Minnesota+55025"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "#a89160", textDecoration: "none", marginTop: "10px", display: "block", "&:hover": { textDecoration: "underline" } }}
            >
              View on Google Maps <AddLocationAltIcon/>
            </Link>
          </Box>

          {/* Phone Section */}
          <Box
            sx={{
              backgroundColor: "#fff",
              padding: { xs: "15px", sm: "20px" },
              marginBottom: "20px",
              borderRadius: "8px",
              border: `1px solid #a89160`,
              transition: "transform 0.3s ease-in-out",
              textAlign: { xs: "center", md: "left" },
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Typography variant="h6" sx={{ display: "flex", alignItems: "center", justifyContent: { xs: "center", md: "flex-start" }, marginBottom: "10px", color: "#a89160" }}>
              <Phone sx={{ marginRight: "10px" }} />
              Phone
            </Typography>
            <Typography>+919876543210</Typography>
          </Box>

          {/* Email Section */}
          <Box
            sx={{
              backgroundColor: "#fff",
              padding: { xs: "15px", sm: "20px" },
              marginBottom: "20px",
              borderRadius: "8px",
              border: `1px solid #a89160`,
              transition: "transform 0.3s ease-in-out",
              textAlign: { xs: "center", md: "left" },
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Typography variant="h6" sx={{ display: "flex", alignItems: "center", justifyContent: { xs: "center", md: "flex-start" }, marginBottom: "10px", color: "#a89160" }}>
              <Email sx={{ marginRight: "10px" }} />
              Email
            </Typography>
            <Typography>nrhinteriors@gmail.com</Typography>
          </Box>

          {/* Social Media Icons */}
          <Typography variant="h6" sx={{ marginTop: "20px", textAlign: { xs: "center", md: "left" }, color: "#a89160" }}>
            Connect with us
          </Typography>
          <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
            <IconButton sx={{ color: "#000", transition: "color 0.3s", "&:hover": { color: "#a89160" } }}>
              <FacebookIcon />
            </IconButton>
            <IconButton sx={{ color: "#000", transition: "color 0.3s", "&:hover": { color: "#a89160" } }}>
              <TwitterIcon />
            </IconButton>
            <IconButton sx={{ color: "#000", transition: "color 0.3s", "&:hover": { color: "#a89160" } }}>
              <InstagramIcon />
            </IconButton>
            <IconButton sx={{ color: "#000", transition: "color 0.3s", "&:hover": { color: "#a89160" } }}>
              <LinkedInIcon />
            </IconButton>
          </Box>
        </Grid>

        {/* Right Section - Form */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: "#fff",
              padding: { xs: "20px", sm: "30px" },
              borderRadius: "8px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              transition: "box-shadow 0.3s ease-in-out",
              "&:hover": {
                boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <Typography variant="h5" gutterBottom sx={{ color: "#a89160" }}>
              Send Message
            </Typography>
            <TextField
              label="Full Name"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: "20px" }}
              InputLabelProps={{ style: { color: "#a89160" } }}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: "20px" }}
              InputLabelProps={{ style: { color: "#a89160" } }}
            />
            <TextField
              label="Type your Message..."
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              sx={{ marginBottom: "20px" }}
              InputLabelProps={{ style: { color: "#a89160" } }}
            />
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#a89160",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#8a6d48",
                },
              }}
            >
              Send
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
    



      <Footer />
    </div>
  );
}

export default ContactUs;
